<template>
  <div>
    <b-card>
      <page-title v-if="titleStatus" :title="$t('tutorialHelps.items')" />

      <main-table
        :fields="fields"
        :list_url="'admin/tutorial-help'"
        :reloadData="reloadData"
        :params="filter"
        :createBtnLabel="$t('tutorialHelps.create')"
        createRouteName="generalSettings.tutorialHelpCreate"
        :excelFields="excelFields"
        excelName="tutorialHelps"
        :downloadSampleStatus="true"
        downloadSampleUrl="tutorial-help/excel-sample"
        :uploadFileStatus="true"
        uploadFileUrl="tutorial-help/excel"
      />
    </b-card>
  </div>
</template>
<script>
import { core } from "../../../../config/pluginInit";
import TutorialHelpFilter from "../models/TutorialHelpFilter";
import searchMixin from "../../../../Mixins/searchMixin";

export default {
  mixins: [searchMixin],
  data() {
    return {
      filter: new TutorialHelpFilter(),
      fields: [
        this.$t("global.selected"),
        { key: "id", label: this.$t("table.id"), sortable: true },
        { key: "heading", label: this.$t("global.heading"), sortable: true },
        {
          key: "description",
          label: this.$t("global.description"),
          sortable: true,
        },
        {
          key: "cover_image",
          label: this.$t("global.coverImage"),
          type: "image",
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          type: "actions",
          actions: [
            {
              text: this.$t("global.view"),
              icon: "fas fa-eye",
              color: "info",
              ifNavigate: true,
              routeName: "generalSettings.tutorialHelpView",
              routeParams: ["id"],
            },
            {
              text: this.$t("global.update"),
              icon: "fas fa-pen",
              color: "primary",
              ifNavigate: true,
              routeName: "generalSettings.tutorialHelpUpdate",
              routeParams: ["id"],
            },
            {
              text: this.$t("global.delete"),
              icon: "fas fa-trash-alt",
              color: "danger",
              showAlert: true,
              actionHeader: this.$t("global.delete"),
              titleHeader: this.$t("tutorialHelps.item"),
              textContent: "name",
              url: "admin/tutorial-help",
              type: "delete",
            },
          ],
        },
      ],
      excelFields: {
        [this.$t("table.id")]: "id",
        [this.$t("global.heading")]: "heading",
        [this.$t("global.url")]: "url",
        [this.$t("table.createdAt")]: "created_at",
      },
    };
  },
  mounted() {
    core.index();
  },
};
</script>
